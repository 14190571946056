import { queryAssignees } from '@crm/services.api';
import Select, { SelectProps } from 'antd/es/select';
import { SearchBox } from 'libs/shared/src/crm-modules/shared';
import React from 'react';
import { UserNameCard, capitalizeFirstLetter } from '@moxie/shared';

interface Props {
  filter: Record<string, unknown>
  className?: string;
  mode?: SelectProps<any>['mode'];
  emptyOption?: React.ReactElement;
  onChange?: (val: string) => void;
  onDropdownVisibleChange?: () => void;
  currentValue?: string | null;
  value?: string | null;
}
const UserSearchBox: React.FC<Props> = (props) => {

  return (
    <SearchBox
      fetchQuery={queryAssignees}
      valueKey="id"
      hideDefaultOptionView
      optionLabelProp="label"
      defaultOpen
      className={props.className}
      maxTagCount={1}
      {...props}
    >
      {
        (option) =>
          <Select.Option value={option.id} disabled={props.currentValue === option.id} label={(`${capitalizeFirstLetter(option.firstName)} ${capitalizeFirstLetter(option.lastName)}`)} className="padding-left-1">
            <UserNameCard
              firstName={option.firstName}
              lastName={option.lastName}
              email={option.email}
              index={option.id}
            />
          </Select.Option>
      }
    </SearchBox>
  );
}

export default UserSearchBox
