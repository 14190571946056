import { API_URL } from '@moxie/constants';
import { CrmResponse, IResponse } from '@shared-components/models';
import { axiosInstance as axios } from './axios';
import crmAxios from './crm-api-axios';
import { ICrmInstitution } from "@model/crm/institution.model";

const getInstitutions = async (params: Record<string, unknown>) => {
  const { data } = await crmAxios.get<CrmResponse<ICrmInstitution>>(`${API_URL.CRM_API_INSTITUTIONS}/`, { params });
  return data;
};

const getInstitutionByService = async (service_id?: string) => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/byService/${service_id}`);
};

const getImportInsitututions = async (params: any) => {
  return axios.get(`${API_URL.INSTITUTIONS_LIST}`, {
    params: {
      ...params,
      search: undefined,
      s: params.search,
      limit: 10,
    },
  });
};

const apiGetInstituteDetail = async (id: string): Promise<IResponse> => {
  const data = await crmAxios.get(`${API_URL.CRM_API_INSTITUTIONS}/${id}`);
  return data?.data;
};

const getInstituteTypes = async () => {
  return await crmAxios.get(`${API_URL.CRM_API_INSTITUTION_TYPES}/`);
};

const saveInstitution = async (body: ICrmInstitution) => {
  return crmAxios.post(`${API_URL.CRM_API_INSTITUTIONS}`, body);
};

const updateInstitution = async (body: Partial<ICrmInstitution>) => {
  return crmAxios.put(`${API_URL.CRM_API_INSTITUTIONS}/${body.id}`, body);
};

const uploadLogo = async (body: any) => {
  return axios.post(`${API_URL.CRM_INSTITUTION}/upload`, body);
};
const getWorkflowsByInstitution = async (
  institution_id: string
): Promise<IResponse> => {
  return (await crmAxios.get(`${API_URL.CRM_API_INSTITUTIONS}/${institution_id}/workflows`))?.data;
};

const deleteInstitution = async (id: number) => {
  return crmAxios.delete(`${API_URL.CRM_API_INSTITUTIONS}/${id}`);
};

const checkInstitutionName = (name: string, id?: number): Promise<any> => {
  const params = {
    name: name,
    id: id,
  };
  return axios.get(`${API_URL.CRM_INSTITUTION}/check/name`, { params });
};

const getServices = async (): Promise<IResponse> => {
  const data = await crmAxios.get(API_URL.CRM_API_SERVICES);
  return data?.data;
};

const getServiceWorkFlow = (id: string): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_WORKFLOW_TYPE}/${id}${API_URL.SERVICE}`);
};

const getWorkflow = async (
  serviceId: string,
  country: string
): Promise<IResponse> => {
  const data = await crmAxios.get(`v1/workflows/query`, {
    params: {
      serviceId,
      country
    }
  });
  return data;
};

const fetchServiceCountry = async (id: string): Promise<IResponse> => {
  const data = await crmAxios.get(`${API_URL.CRM_API_SERVICES}/${id}/countries`);
  return data?.data;
};

const importInstitution = (items: string[]): Promise<IResponse> => {
  return axios.post(`${API_URL.INSTITUTIONS}/imports`, items);
};

const getCRMWorkflowsByInstitutionId = async (
  id: string,
  params = ''
): Promise<IResponse> => {
  return axios.get(`${API_URL.CRM_INSTITUTION}/workflows/${id}/${params}`);
};

const getProductsListBiInstitutionId = async (params: any) => {
  return (await crmAxios.get(API_URL.CRM_API_PRODUCTS, { params }))?.data;
};

export {
  getInstitutions,
  saveInstitution,
  getInstituteTypes,
  updateInstitution,
  deleteInstitution,
  uploadLogo,
  checkInstitutionName,
  getWorkflowsByInstitution,
  getInstitutionByService,
  getServices,
  getServiceWorkFlow,
  getWorkflow,
  fetchServiceCountry,
  apiGetInstituteDetail,
  getImportInsitututions,
  importInstitution,
  getCRMWorkflowsByInstitutionId,
  getProductsListBiInstitutionId
};
