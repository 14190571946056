import { updateEnquiryData } from '@crm/services.api';
import { UpdateContactEnquiryPayload } from '@model/crm/enquiry-form.model';
import { useMutation } from '@tanstack/react-query';
import { crmErrorHandler } from 'libs/shared/src/crm-modules/shared';


export const useUpdateEnquiryData = (triggerRefresh?: () => any) => {
  return useMutation({
    mutationKey: ['edit-enquiry-data'],
    mutationFn: (data: Partial<UpdateContactEnquiryPayload>) => updateEnquiryData(data.id as string, data),
    onSuccess: () => triggerRefresh && triggerRefresh(),
    onError: (error) => {
      crmErrorHandler(error);
    },
  });
};
