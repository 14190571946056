import React, { useEffect, useMemo, useState } from 'react';
import Select from "antd/es/select";
import { CITY_LABEL, COUNTRIES, regex, VALIDATION_MESSAGE } from "@moxie/constants";
import Form from 'antd/es/form';
import Card from 'antd/es/card';
import { Row } from 'antd/lib/grid';
import { Col } from 'antd/es/grid';
import Typography from 'antd/es/typography';
import { FormInstance, Input } from 'antd';
import countriesData from "@crm/src/pages/enquiry-form/country-state-data.json";

interface AddressInfoCardProps {
  editMode: boolean;
  form: FormInstance;
}

interface ICountryStateData {
  [country: string]: string[];
}

const countriesStateData: ICountryStateData = countriesData;

const EnquiryViewAddressInfo = ({ editMode, form }: AddressInfoCardProps) => {
  const [selectedCountry, setSelectedCountry] = useState<string | undefined>(undefined);

  const filteredStateList = useMemo(() => {
    return selectedCountry ? countriesStateData[selectedCountry].sort() : [];
  }, [selectedCountry]);

  const handleCountryChange = (value: string) => {
    setSelectedCountry(value);
    form.setFieldsValue({
      addressInformation: {
        'state': undefined,
        'city': undefined,
      }
    })
  }

  const countriesList = Object.keys(countriesStateData).sort();

  useEffect(() => {
    const initialCountryVal = form.getFieldValue(["addressInformation", "country"]);
    if (initialCountryVal) {
      setSelectedCountry(initialCountryVal);
    }
  }, [editMode])

  return (
    <Card className="margin-top-2" title={<Typography.Text strong>Address Information</Typography.Text>} headStyle={{ background: "none" }}>
      <Row>
        <Col span={11}>
          <Form.Item
            label="Country"
            name={["addressInformation", "country"]}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please select country',
              },
            ]}
          >
            <Select
              placeholder="Please select country"
              showSearch
              optionFilterProp="searchprop"
              onChange={handleCountryChange}
              disabled={!editMode}
            >
              {countriesList.map((country, index) => (
                <Select.Option value={country} key={index} searchprop={country}>
                  {country}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <Form.Item
            name={['addressInformation', 'state']}
            label="State"
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please select state',
              },
            ]}
          >
            <Select
              optionFilterProp="searchprop"
              allowClear={false}
              disabled={!editMode}>
              {filteredStateList.map((state: string) => {
                return (
                  <Select.Option value={state} key={state} searchprop={state}>
                    {state}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={11}>
          <Form.Item
            name={["addressInformation", "city"]}
            label={CITY_LABEL}
            validateFirst={true}
            rules={[
              {
                required: true,
                message: 'Please enter city',
              },
              {
                pattern: new RegExp(regex.ONLY_STRING),
                message: VALIDATION_MESSAGE.INVALID_TEXT_INPUT,
              },
              { max: 50 },
            ]}
          >
            <Input className={!editMode ? 'enquiry-view-form__cursor-default' : ''} placeholder="Please enter city" readOnly={!editMode} />
          </Form.Item>
        </Col>

        <Col span={11} offset={2}>
          <Form.Item
            label="Country of Passport"
            name={["addressInformation", "countryOfPassport"]}
          >
            <Select
              placeholder={!editMode ? "N/A" : "Select a country"}
              showSearch
              optionFilterProp="searchprop"
              allowClear
              disabled={!editMode}
            >
              {COUNTRIES.map((country) => (
                <Select.Option value={country.name} key={country.code} searchprop={country.name}>
                  {country.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

    </Card>
  )
}

export { EnquiryViewAddressInfo };
