import React from "react";
import { ServiceList } from "@crm/src/shared/interested-service-tree/service-list-card";
import { Card, Col, Row, Typography } from "antd";
import moment from "moment";
import { UnassignedCard, UserAvatarGroup } from "@shared-components/elements";
import { DealsInterface } from "@model/crm/deal.model";

interface ActiveDealsProps {
  deal: DealsInterface;
}

const ActiveDealsCard = ({ deal }: ActiveDealsProps) => {
  return (
    <Card className="office-visit__enquiries__card">
      <Row justify="space-around">
        <Col span={17}>
          <Row>
            <Col span={4}>
              <Row><Typography.Text className="office-visit__enquiries__label">Deal Id</Typography.Text></Row>
              <Row><Typography.Text ellipsis className="office-visit__enquiries__text">{deal?.internalId}</Typography.Text></Row>
            </Col>
            <Col span={19} offset={1}>
              <Row><Typography.Text className="office-visit__enquiries__label">Deal Name</Typography.Text></Row>
              <Row><Typography.Text ellipsis className="office-visit__enquiries__text">{deal?.name}</Typography.Text></Row>
            </Col>
          </Row>

        </Col>
        <Col span={6} offset={1}>
          <Row><Typography.Text className="office-visit__enquiries__label">Processing Branch</Typography.Text></Row>
          <Row><Typography.Text ellipsis className="office-visit__enquiries__text">{deal?.branch?.name}</Typography.Text></Row>
        </Col>
      </Row>
      <Row className="margin-top-1">
        <Col span={17}>
          <Row><Typography.Text className="office-visit__enquiries__label">Interested Services</Typography.Text></Row>
          <Row>
            {deal?.payload ?
              <ServiceList currentService={{
                parent: deal.payload?.typeOfService,
                countries: deal?.payload?.countries,
                interests: Array.isArray(deal?.payload?.services) ? deal.payload?.services : [deal?.payload?.services].filter(Boolean) as string[],
                id: deal?.interestedServiceId
              }} /> : null}
          </Row>
        </Col>
        <Col span={6} offset={1}>
          <Row><Typography.Text className="office-visit__enquiries__label">Created Date: </Typography.Text></Row>
          <Row><Typography.Text ellipsis className="office-visit__enquiries__text">{moment(deal?.createdAt).format("DD-MM-YYYY")}</Typography.Text></Row>
        </Col>
      </Row>
      <Row className="margin-top-1">
        <Col span={17}>
          <Row><Typography.Text className="office-visit__enquiries__label">Assignee</Typography.Text></Row>
          <Row>
            {deal?.assignees ? (
              <UserAvatarGroup userList={deal?.assignees} />
            ) : <UnassignedCard />}
          </Row>
        </Col>
        <Col span={6} offset={1}>
          <Row><Typography.Text className="office-visit__enquiries__label">Stage</Typography.Text></Row>
          <Row className="w-100">
            <Row><Typography.Text className="initial_capital office-visit__enquiries__text" ellipsis>{deal?.status}</Typography.Text></Row>
          </Row>
        </Col>
      </Row>
    </Card>
  )
}
export { ActiveDealsCard }
